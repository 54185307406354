import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RouteSoulGuard } from './providers/route-soul.guard';
import { RouteGuard } from './providers/route.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [RouteGuard]
  },
  {
    path: 'trauma-frequencies',
    loadChildren: () => import('./pages/trauma-frequencies/trauma-frequencies.module').then( m => m.TraumaFrequenciesPageModule),
    canActivate: [RouteGuard]
  },
  {
    path: 'soul-app',
    loadChildren: () => import('./pages/soul-app/soul-app.module').then( m => m.SoulAppPageModule),
    canActivate: [RouteSoulGuard]
  },
  { 
    path: '**', 
    redirectTo: 'login', 
    pathMatch: 'full' 
  },
  {
    path: 'anta',
    loadChildren: () => import('./anta/anta.module').then( m => m.AntaPageModule)
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
