import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {HttpResponse} from "../interfaces/http.interface";


export interface ILoginRequest {
  email: string;
  password: string;
}

export interface IUser {
  firstname: string;
  permissions: any;
  surname: string;
  userId: number;
}

export interface ILoginResponse {
  token: string;
  user: IUser;
  acceptedTermsAndConditions: boolean
  userId: string;
  adminUser: boolean
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient: HttpClient) { }

  login(userDetails: ILoginRequest): Observable<HttpResponse<ILoginResponse>>{
    return this.httpClient.post<HttpResponse<ILoginResponse>>(environment.baseUrl + '/auth/login', userDetails);
  }

  acceptTerms(): Observable<any>{
    return this.httpClient.get<any>(environment.baseUrl + '/auth/accepttermsandconditions');
  }

  setLocalToken(token, userType){
    localStorage.setItem('token', token);
    localStorage.setItem('userType', userType)
  }

  getLocalToken(){
    return {token: localStorage.getItem('token'), userType:  localStorage.getItem('userType')};
  }
}
