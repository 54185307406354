import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICardData } from 'src/app/interfaces/card.interface';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  
  @Input() cardData: ICardData[];

  constructor(private router: Router) { }

  ngOnInit() {}

  route(link){
    this.router.navigate([link])
  }

}
