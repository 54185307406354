import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/api/auth.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {

  constructor(
    private router: Router,
    private modalController: ModalController,
    private authService: AuthService
    ) { }

  ngOnInit() {}

  decline(){
    this.modalController.dismiss().then(()=>{
      this.router.navigate(['login'])
    })
  }

  accept(){
    this.authService.acceptTerms().subscribe(result => {
      if(result.resultCode == 200){
        localStorage.setItem('terms', 'true')
        this.modalController.dismiss()
      } else {
        this.router.navigate(['login'])
      }
    })
  }

}
