import { HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../../api/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{

  constructor(private authService: AuthService) { }

  intercept(req, next){
    if(this.authService.getLocalToken()?.token !== undefined){
      const tokenizedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getLocalToken().token}`
        }
      });
      return next.handle(tokenizedReq);
    }
    return next.handle(req);
  }

}
