import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { PopoverComponent } from '../popover/popover.component';
import {AuthService} from "../../api/auth.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {


  constructor(public router: Router,
              public popoverController: PopoverController,
              private authService: AuthService) { }

  ngOnInit() {}

  async presentPopover(ev: any) {

    const popover = await this.popoverController.create({
      component: PopoverComponent,
      event: ev,
      cssClass: 'custom-popover',
    });
    return await popover.present();
  }

  logout(){
    this.authService.setLocalToken('', '');
    localStorage.clear();
    this.router.navigate(['login'])
  }

  navFrequencies(){
    this.router.navigate(['trauma-frequencies'])
  }

  navHome(){
    if(this.router.url.includes('soul-app')){
      this.router.navigate(['soul-app'])
    } else {
      this.router.navigate(['home'])
    }

  }

}
