import { Component, Input, OnInit } from '@angular/core';
import { IBreadCrumb } from 'src/app/interfaces/breadcrumb.interface';
import { Router, Event, NavigationStart } from '@angular/router';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {

  breadcrumbs: IBreadCrumb[];
  @Input() breadcrumb: string;
  disabled: boolean = false

  breadcrumbTemp: string[] = []
  linkString: string = '/'

  constructor(public router: Router) { 
    this.router.events.subscribe((event: Event) => {
      this.linkString = ''
      if (event instanceof NavigationStart) {
        this.breadcrumbs = []
        if(event.url == '/soul-app' || event.url == '/login' || event.url == '/home'){
          this.disabled = false
        } else {
          this.disabled = true
        }
        this.breadcrumbTemp = event.url.split('/')
        this.breadcrumbTemp.forEach(x => {
          this.linkString = this.linkString + '/' + x
          let objectItem: IBreadCrumb = {
            name: x,
            link: this.linkString
          }
          this.breadcrumbs.push(objectItem)
        })
        this.breadcrumbs.splice(0,1)
      }
  });
  }

  ngOnInit() {}
}
