import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../api/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RouteSoulGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.authService.getLocalToken()?.token == '' || this.authService.getLocalToken()?.userType == 'false'){
      this.router.navigateByUrl('/login');
      console.log('route-soul-false')
      return false;
    } else {
      console.log('route-soul-true')
      return true;
    }
  }
  
}
